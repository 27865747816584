import axios from 'axios'

export const urlBackend =
  process.env.NODE_ENV === 'production'
    ? 'https://server.wittygrass-50c23bcb.brazilsouth.azurecontainerapps.io/'
    : 'http://localhost:8080/'

const api = axios.create({
  baseURL: urlBackend,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Accept: '*/*',
    'Content-Type': 'application/json',
    'Accept-Encondig': 'gzip, deflate, br'
  }
});

api.interceptors.response.use(function (response) {
  //deu cereto
  return response;
}, function (err) {
  //deu erro
  if (err.response.data.user_id) {
    if (err.response.data.Toast.description === 'NeedToAnswerFormExitToast') {
      window.location.href = `/employee/details/${err.response.data.user_id}/formexit`;
    } else if (err.response.data.Toast.description === 'NeedToAnswerToast') {
      localStorage.removeItem("@token/iteams");
      localStorage.removeItem("@notificationCount/iteams");
      localStorage.removeItem("@role/iteams");

      window.location.href = `/employee/details/${err.response.data.user_id}/exit`;
    }
  }

  return Promise.reject(err);
});

export default api
